.fade-container {
  position: relative;
  /* display: block; */
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

.pic-min {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 20;
  filter: blur(5px);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0;

  animation: name-of-animation 4s;
}

@keyframes name-of-animation {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.pic {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-animation: fade 28s infinite;
  animation: fade 28s infinite;
}

#pic1 {
  animation-delay: 0s;
}

#pic2 {
  animation-delay: 7s;
}

#pic3 {
  animation-delay: 14s;
}

#pic4 {
  animation-delay: 21s;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fade {
  0% {
    opacity: 1;
  }

  15% {
    opacity: 1;
  }

  25% {
    opacity: 0;
  }

  90% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.game-detail-panel {
  width: 24rem;
  z-index: 50;
  position: relative;
  background: #1a1b1ed0;
  backdrop-filter: blur(14.4px);
  -webkit-backdrop-filter: blur(14.4px);
}

.game-detail-mini-panel {
  width: 3rem;
  height: 3rem;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1a1b1e3f;
  transition: all 0.5s ease-out;
}

.game-detail-mini-panel:hover {
  background: #1a1b1ed0;
}