.showcase-container{
    min-width: 20vw;
    color: white;
    margin: 0px -3% 0px -3%;
    clip-path: polygon(25% 0%, 100% 0%, 75% 100%, 0% 100%);
    height: 100%;
    position:relative;
}
.showcase-container:first-of-type{
    clip-path: polygon(0% 0%, 100% 0%, 75% 100%, 0% 100%);
}

.showcase-container:last-of-type{
    clip-path: polygon(25% 0%, 100% 0%, 100% 100%, 0% 100%);
}

.showcase-container.small{
    min-width: 16rem;
    max-width: 16rem;
    margin: 0px -3rem 0px -3rem;
}


.showcase-item{
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
}
.showcase-name{
    filter: blur(0px) !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
    pointer-events: none;
}
.showcase-item.obama{
    background-image: url("../../../public/obama.png");
}
.showcase-item.frog-fish{
    background-image: url("../../../public/frog-fish.png");
}
.showcase-item.rigworks{
    background-image: url("../../../public/rigworks.png");
}
.showcase-item.piga-gong{
    background-image: url("../../../public/piga-gong.png");
}
.showcase-item.skux{
    background-image: url("../../../public/skux.png");
}
.showcase-item.unity-td{
    background-image: url("../../../public/unity-td.png");
}
.showcase-item.origin{
    background-image: url("../../../public/origin.png");
}
.showcase-item.blur{
    filter: blur(3px);
    transition: filter 0.4s ease;
    transition: box-shadow 0.4s ease;
    cursor: pointer;
    box-shadow: 5px 10px 15px 0 #030005 inset, -5px -10px 15px 0 #030005 inset, rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.showcase-item.blur:hover{
    filter: blur(0px);
    box-shadow: 5px 5px 5px 0 #030005 inset, -5px -5px 5px 0 #030005 inset, rgba(0, 0, 0, 0.35) 0px 5px 15px;
}


/* Glitch */
.glitch {
    position: relative;
}
@keyframes noise-anim {
    0% {
        clip: rect(23px, 9999px, 28px, 0);
   }
    5% {
        clip: rect(22px, 9999px, 60px, 0);
   }
    10% {
        clip: rect(38px, 9999px, 18px, 0);
   }
    15% {
        clip: rect(44px, 9999px, 26px, 0);
   }
    20% {
        clip: rect(5px, 9999px, 75px, 0);
   }
    25% {
        clip: rect(51px, 9999px, 45px, 0);
   }
    30% {
        clip: rect(41px, 9999px, 23px, 0);
   }
    35% {
        clip: rect(35px, 9999px, 23px, 0);
   }
    40% {
        clip: rect(63px, 9999px, 76px, 0);
   }
    45% {
        clip: rect(63px, 9999px, 6px, 0);
   }
    50% {
        clip: rect(84px, 9999px, 71px, 0);
   }
    55% {
        clip: rect(1px, 9999px, 38px, 0);
   }
    60% {
        clip: rect(9px, 9999px, 26px, 0);
   }
    65% {
        clip: rect(54px, 9999px, 95px, 0);
   }
    70% {
        clip: rect(79px, 9999px, 9px, 0);
   }
    75% {
        clip: rect(100px, 9999px, 93px, 0);
   }
    80% {
        clip: rect(68px, 9999px, 31px, 0);
   }
    85% {
        clip: rect(21px, 9999px, 62px, 0);
   }
    90% {
        clip: rect(84px, 9999px, 4px, 0);
   }
    95% {
        clip: rect(18px, 9999px, 53px, 0);
   }
    100% {
        clip: rect(67px, 9999px, 65px, 0);
   }
}
.glitch:after {
    content: attr(data-text);
    position: absolute;
    left: 2px;
    text-shadow: -1px 0 red;
    top: 0;
    color: black;
    background: white;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim 5s infinite linear alternate-reverse;
}
@keyframes noise-anim-2 {
    0% {
        clip: rect(66px, 9999px, 75px, 0);
   }
    5% {
        clip: rect(23px, 9999px, 45px, 0);
   }
    10% {
        clip: rect(70px, 9999px, 34px, 0);
   }
    15% {
        clip: rect(69px, 9999px, 30px, 0);
   }
    20% {
        clip: rect(86px, 9999px, 50px, 0);
   }
    25% {
        clip: rect(97px, 9999px, 11px, 0);
   }
    30% {
        clip: rect(88px, 9999px, 75px, 0);
   }
    35% {
        clip: rect(32px, 9999px, 66px, 0);
   }
    40% {
        clip: rect(35px, 9999px, 17px, 0);
   }
    45% {
        clip: rect(37px, 9999px, 86px, 0);
   }
    50% {
        clip: rect(15px, 9999px, 17px, 0);
   }
    55% {
        clip: rect(63px, 9999px, 85px, 0);
   }
    60% {
        clip: rect(29px, 9999px, 29px, 0);
   }
    65% {
        clip: rect(49px, 9999px, 3px, 0);
   }
    70% {
        clip: rect(2px, 9999px, 84px, 0);
   }
    75% {
        clip: rect(42px, 9999px, 68px, 0);
   }
    80% {
        clip: rect(36px, 9999px, 88px, 0);
   }
    85% {
        clip: rect(35px, 9999px, 72px, 0);
   }
    90% {
        clip: rect(19px, 9999px, 58px, 0);
   }
    95% {
        clip: rect(70px, 9999px, 19px, 0);
   }
    100% {
        clip: rect(12px, 9999px, 13px, 0);
   }
}
.glitch:before {
    content: attr(data-text);
    position: absolute;
    left: -2px;
    text-shadow: 1px 0 blue;
    top: 0;
    color: white;
    background: black;
    overflow: hidden;
    clip: rect(0, 900px, 0, 0);
    animation: noise-anim-2 3s infinite linear alternate-reverse;
}