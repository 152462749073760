.invert {
    background: black;
    filter: invert(1) hue-rotate(310deg);
    height: 100%;
}
.ReactBash {
    border-radius: 0px !important;
    width: 100%;
}

.ReactBash input:focus-visible {
    outline: none !important;
}

.ReactBash > div:first-of-type {
    max-height: 0px !important;
    border: none !important;
    outline: none !important;
    padding: 0px !important;
    margin: 0px !important;
}