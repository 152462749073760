#company-logo h1 {
    font-weight: 900;
    font-size: 30pt;
    font-family: 'Rubik', sans-serif;


    -webkit-background-clip: text;
    background-clip: text;
    
    color: #ffffffb3;
    transition: color 0.3s ease;
    background-size: contain;
    cursor: pointer;
}

#company-logo h1:hover {
    color: transparent;
}

#company-logo h1#monkey {
    background-image: url("../../../public/monkey.png");
}

#company-logo h1#rat {
    background-image: url("../../../public/rat.png");
}

#company-logo h1#software {
    color: #427bac73;
    background-image: url("../../../public/devil.png");
}

#company-logo h1#software:hover {
    color: transparent;
}